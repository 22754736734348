<template>
  <div class="" data-cy="content-profile-view">
    <div class="flex justify-between">
      <h1
        class="header-text"
        ref="headerProfile"
        aria-labelledby="Header for profile page"
        data-cy="header-profile"
      >
        Profile
      </h1>
      <div
        v-if="
          this.$store.state.user_selected_term.name ===
          this.lookup_current_term.name
        "
        class=""
      >
        <button
          class="inline-block py-3 px-9 bg-purple hover:bg-dark-purple rounded-full text-white"
          @click="$router.push({ name: 'ProfileEdit' })"
          data-cy="profile-edit"
        >
          Edit
        </button>
      </div>
    </div>
    <student-profile-view
      :student="student"
      :acad_lvl="acad_level"
      class="pt-5"
      v-if="!student.student_loading"
    />
    <div class="pt-5" v-else>
      <div class="box-style">
        <div class="flex items-center justify-center">
          <font-awesome-icon icon="spinner" spin />
          <p class="ml-2">LOADING</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import StudentProfileView from "@/components/reusable-components/StudentProfileView";

export default {
  name: "Profile",
  components: { StudentProfileView },
  mounted() {
    //added for accessability
    this.$refs.headerProfile.focus();
  },
  computed: {
    ...mapGetters(["lookup_current_term"]),
    ...mapState({
      student: (state) => state.student,
      acad_level: (state) => state.acad_level,
    }),
  },
};
</script>
